import React, { useEffect, useContext, useState } from 'react'
import { Flex, Box, Text } from 'theme-ui'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { ModalContext } from '@solid-ui-components/Modal'
import Divider from '@solid-ui-components/Divider'

const Karsilama = ({ content: { images } }) => {

  const { setActiveModal } = useContext(ModalContext)

  return (
    <>
      <Divider space='5' />
      <h3 style={{ color: "black", textAlign: "center" }}></h3>
      <div style={{ marginLeft: "20%", marginRight: "20%", textAlign: "center" }} >
        <Text variant="h3" color="beta"> Sitemize Hoş Geldiniz </Text>
        <Text color="black"> Paket fiyatlarına KDV Dahildir </Text>
        <Divider space='1' />
        <Text color="black"> Modelleri, ilk açılışta gelen ana ölçülerde ücretsiz olarak indirerek test edebilirsiniz </Text>
        <Divider space='1' />
        <Text color="black"> Tüm modellerde ücretsiz canlı destek alabilirsiniz</Text>
        <Divider space='1' />
        <Text color="black"  > Özel indirimlerimizden yararlanmak ve paketleri incelemek için lütfen üye olunuz veya giriş yapınız </Text>
        <Divider space='1' />
        <Text color="black"  > Üyelik ücretsizdir </Text>
        <Box>
          <Text key={`lblicolcumesaj`} as='span' color='alpha' sx={{
          }} > <h1 onClick={e => {
            setActiveModal('authentication');
          }} style={{ cursor: "pointer", textDecoration: "underline" }}>
              {"Giriş / Üyelik İçin Tıklayınız"} </h1>      </Text>
        </Box>
      </div>
      <Divider space='5' />
    </>
  )
}


export default WithDefaultContent(Karsilama)
