import React, { useEffect, useContext, useState } from 'react'
import { Flex, Box, Text } from 'theme-ui'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'
import { ModalContext } from '@solid-ui-components/Modal'
import Karsilama from '@solid-ui-blocks/Karsilama'
import httpService from '../../service/httpService'; 
import Fiyat from '@solid-ui-blocks/Pricing/Fiyat'

const Fiyatlanirma = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)


  const [paketListesi, setList] = useState([]);


  useEffect(() => { 

    httpService.post("UnLocked/PaketList", { }).then((res) => {
      if (res.DogruMu) {
        res.Data[0].textGroup = res.Data.map(function (v) {
          return v.UyelikTipiText;
        });
        res.Data.forEach(ktpaketList => {
          ktpaketList.PaketIcerikleri.forEach(paketIcerikList => {
            paketIcerikList.KrediListesi[0].SeciliMi = true;
          });
        });
        setList(res)
      } else {
      //  alert(res.Mesaj);
      }
    }).catch((err) => {
    
    });
  }, [])


  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Lisans Paketleri' />
      {/* <Karsilama></Karsilama> */}
      <Divider space='5' /> 
      <Fiyat content={paketListesi} />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageFiyatlanirmaContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default Fiyatlanirma
